<template>
    <Toast />
    <div style="padding: 24px 0px 0px 0px">
        <div>
            <div class="flex mt-2" v-if="issueIDLocal == null">
                <div class="p-float-label">
                    <Dropdown v-model="selectedField" :options="searchfields" optionLabel="name"
                        class="w-full md:w-15rem" />
                    <label>{{this.$langGetValue("searchOptions")}}</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 1" class="p-float-label ml-3">
                    <InputNumber @keyup.enter="search" v-model="issueID" :useGrouping="false" />
                    <label for="issueID"><i class="pi pi-search" style="font-weight: 900;"></i>
                        {{this.$langGetValue("idissue")}}</label>
                </div>
                <div v-if="selectedField != null &&  [2, 3, 4, 5].includes(selectedField.id)" class="p-float-label ml-3">
                    <MultiSelect v-model="controlAreasSelected" :options="controlAreas" optionLabel="label"
                        class="w-full md:w-20rem" style="white-space: none !important;" :maxSelectedLabels="1" />
                    <label>{{this.$langGetValue("issueOrigin")}}</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 2" class="p-float-label ml-3 customLabel">
                    <!-- <InputText v-model="generalSearch" class="customInput" /> -->
                    <Chips v-model="generalSearch" separator="," class="customInput" :addOnBlur="true" @keyup.enter="search" />
                    <label for="issueID"><i class="pi pi-comment" style="font-weight: 900;"></i>
                        {{this.$langGetValue("issuetitle")}}</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 3" class="p-float-label ml-3 customLabel">
                    <!-- <InputText v-model="generalSearch" class="customInput" /> -->
                    <Chips v-model="generalSearch" separator="," class="customInput" :addOnBlur="true" @keyup.enter="search" />
                    <label for="issueID"><i class="pi pi-comment" style="font-weight: 900;"></i>
                        {{this.$langGetValue("description")}}</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 5" class="p-float-label ml-4">
                    <MultiSelect class="w-full md:w-20rem"
                    v-model="selectedStatus" :options="statusIssue" optionLabel="label"
                            :filter="true" ref="system" />
                        <label>{{this.$langGetValue("issuestatus")}}</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 6" class="p-float-label ml-3">
                    <Chips v-model="generalSearch" separator="," class="customInput" :addOnBlur="true" @keyup.enter="search" />
                    <label for="issueID"><i class="pi pi-search" style="font-weight: 900;"></i>
                        {{this.$langGetValue("issuereportnumber")}}</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 7" class="p-float-label ml-3">
                    <!-- <InputText v-model="generalSearch" class="customInput" /> -->
                    <Chips v-model="generalSearch" separator="," class="customInput w-full md:w-20rem" :addOnBlur="true" />
                    <label for="issueID"><i class="pi pi-comment" style="font-weight: 900;"></i>
                        {{this.$langGetValue("issueoriginresponsible")}}</label>
                </div>
                <div v-if="selectedField != null" class="text-left ml-3">
                    <button class="primaryButton" :disabled="loadingIconLocal" @click="search"
                        :title="this.$langGetValue('menuButtonSearch')" severity="secondary">
                        <i :class="{
                        'pi pi-search': loadingIconLocal == false,
                        'pi pi-spin pi-spinner': loadingIconLocal == true,
                        }" style="font-weight: 900"></i>
                    </button>
                </div>
            </div>
            <div v-if="selectedField != null && selectedField.id == 3" class="mt-3">
             <!--hierarchy component-->
            </div>
        </div>
    </div>
    <div v-if="issueListReturn" v-show="localLength > 0" class="mt-3">
        <Accordion ref="results" class="customAccordionTab1" v-model:activeIndex="currentIndexTab">
          <AccordionTab style="background-color: #f9f9f9">
            <template #header>
              <div style="width: 100%" class="flex align-items-center justify-content-between">
                <div>
                  <b>{{ this.$langGetValue("result") }}:</b>
                </div>
                <div style="font-weight: normal">
                  <i>{{ this.localLength }}
                    {{ this.$langGetValue("correspondingIssues") }}</i>
                </div>
              </div>
            </template>
            <!-- @page-items-length="printNumberPages" -->
            <IssueResult :analyticsOnSearch="true" :query="query" buttonMode="onlyView" @update-length="updateLength"
              @page-data="handlePageData">
            </IssueResult>
          </AccordionTab>
        </Accordion>
      </div>
    <div>
        <div class="mt-2" v-if="searchIssueReturn">
            <issueComponent :issueData="issueData" :issueMode="issueMode"></issueComponent>
        </div>
    </div>
</template>

<script>
import issueComponent from "../components/IssueComponent.vue"
import IssueResult from "../components/IssueResult.vue"
import IssueService from "../service/IssueService.js";
import EventBus from "../AppEventBus"
import FieldService from "../service/FieldService";
import ContentService from "../service/ContentService";

export default {
    components: {
        IssueResult,
        issueComponent,
    },
    data() {
        return {
            loadingIconLocal: false,
            selectedHashtags: [],
            filteredHashtags: [],
            issueListReturn: false,
            searchIssueReturn: false,
            localLength: 0,
            hashtags: [],
            issueMode: "view",
            selectedDate: null,
            currentIndexTab: null,
            initialDate: null,
            finalDate: null,
            selectedAreas: {},
            selectedStatus: null,
            controlAreas: null,
            controlAreasSelected: [],
            advancedFilter: false,
            statusIssue: null,
            issueID: null,
            areaHierarchy: null,
            issueData: null,
            selectedField: { name: this.$langGetValue("idissue"), id: 1 },
            controlAreaFilter: null,
            generalSearch: [],
            searchfields: [
                { name: this.$langGetValue("idissue"), id: 1 },
                { name: this.$langGetValue("issuetitlemoreissueorigin"), id: 2 },
                { name: this.$langGetValue("issuedescriptionmoreissueorigin"), id: 3 },
                //v2: { name: 'Área', id: 3 },
                { name: this.$langGetValue("issuestatusmoreissueorigin"), id: 5 },
                { name: this.$langGetValue("issueOrigin"), id: 4 },
                { name: this.$langGetValue("issuereportnumber"), id: 6 },
                //v2: { name:this.$langGetValue("issueoriginresponsible"), id: 7 }
            ],
            issueList: [],
        };
    },
    created() {
        EventBus.on('searchIssueByID', this.searchissueByID);
    },
    mounted() {
        FieldService.fetch(ContentService.currentLang)
        this.statusIssue = FieldService.data.issue.issueStatus
        this.controlAreas = FieldService.data.issue.issueOrigin;
        this.hasUrlId()
    },
    watch: {
        initialDate() {
            this.finalDate = null
        },
        selectedField() {
            this.issueListReturn = false;
            this.searchIssueReturn = false;
        },
    },
    methods: {       
        searchHashtags(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredHashtags = this.hashtags;
                } else {
                    let tempArray = []

                    this.hashtags.forEach((hashtag) => {
                        if (hashtag.filter.some((filter) => filter.includes(event.query.toLowerCase()))) {
                            tempArray.push(hashtag)
                        }
                    })
                    this.filteredHashtags = tempArray

                }
            }, 250);
        },
        hasUrlId() {
            if (this.$route.query.id != undefined) {
                this.issueID = this.$route.query.id
                this.selectedField = { name: this.$langGetValue("idissue"), id: 1 }

                if(this.$route.query.mode != undefined){
                    this.issueMode = this.$route.query.mode
                }

                this.searchissueByID(this.issueID, this.issueMode)
            }
        },
        handlePageData(list) {
            this.resultNumberOfRows = list.length;
            this.summaryIDs = list.slice();
        },
        updateLength(value) {
            this.localLength = value;
            this.loadingIconLocal = false;
        },
        search() {
            this.loadingIconLocal = true;

            this.issueListReturn = false;
            this.searchIssueReturn = false;
            this.localLength = 0;

            switch (this.selectedField.id) {
                case 1:
                    if (this.issueID == null || this.issueID == "") {
                        this.$toast.add({
                            severity: "error",
                            summary: this.$langGetValue('toast5'),
                            detail: this.$langGetValue('toast5Sub'),
                            life: 10000,
                        });
                        this.loadingIconLocal = false;
                        return
                    }

                    this.searchissueByID(this.issueID)

                    break;
                // title
                case 2:
                    if (this.generalSearch.length == 0) {
                        this.loadingIconLocal = false;
                        this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast5"),
                        detail: this.$langGetValue("toast5Sub"),
                        life: 3000,
                        });
                        return;
                    }

                    var payloadTitle = {
                        orderBy: [
                        {
                            field: "statusOrder",
                            direction: "ASC",
                        },
                        {
                            field: "progress",
                            direction: "DESC",
                        },
                        {
                            field: "finalDate",
                            direction: "ASC",
                        }
                        ],
                        sqlWhereClause: {
                        condition: "AND",
                        rules: [],
                        },
                    };

                    payloadTitle.sqlWhereClause.rules.push({
                    condition: "AND",
                    rules: [],
                    })
                    this.generalSearch.forEach((word) => {
                        payloadTitle.sqlWhereClause.rules[0].rules.push({
                        field: "title",
                        type: "string",
                        input: "text",
                        operator: "contains",
                        value: word,
                        });
                    });
                    
                    if (this.controlAreasSelected.length > 0) {
                        payloadTitle.sqlWhereClause.rules.push({
                            condition: "OR",
                            rules: [],
                        })
                        this.controlAreasSelected?.forEach((ca) => {
                            payloadTitle.sqlWhereClause.rules[1].rules.push({
                                field: "issueOrigin",
                                type: "integer",
                                input: "number",
                                operator: "in",
                                value: [ca.id],
                            })
                        })
                    }

                    this.query = payloadTitle;

                    this.currentIndexTab = 0;
                    this.loadSearchResult();

                    break;

                case 3:
                    if (this.generalSearch.length == 0) {
                        this.loadingIconLocal = false;
                        this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast5"),
                        detail: this.$langGetValue("toast5Sub"),
                        life: 3000,
                        });
                        return;
                    }

                    var payloadDescription = {
                        orderBy: [
                        {
                            field: "statusOrder",
                            direction: "ASC",
                        },
                        {
                            field: "progress",
                            direction: "DESC",
                        },
                        {
                            field: "finalDate",
                            direction: "ASC",
                        }
                        ],
                        sqlWhereClause: {
                        condition: "AND",
                        rules: [],
                        },
                    };


                    payloadDescription.sqlWhereClause.rules.push({
                    condition: "AND",
                    rules: [],
                    })
                    this.generalSearch.forEach((word) => {
                        payloadDescription.sqlWhereClause.rules[0].rules.push({
                        field: "descriptionSanitized",
                        type: "string",
                        input: "text",
                        operator: "contains",
                        value: word,
                        });
                    });

                    if (this.controlAreasSelected.length > 0) {
                        payloadDescription.sqlWhereClause.rules.push({
                            condition: "OR",
                            rules: [],
                        })
                        this.controlAreasSelected?.forEach((ca) => {
                            payloadDescription.sqlWhereClause.rules[1].rules.push({
                                field: "issueOrigin",
                                type: "integer",
                                input: "number",
                                operator: "in",
                                value: [ca.id],
                            })
                        })
                    }

                    this.query = payloadDescription;

                    this.currentIndexTab = 0;
                    this.loadSearchResult();

                    break;



                case 4:
                if (this.controlAreasSelected.length == 0) {
                    this.loadingIconLocal = false;
                    this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("toast5"),
                    detail: this.$langGetValue("toast5Sub"),
                    life: 3000,
                    });
                    return;
                }

                var payloadCA = {
                    orderBy: [
                    {
                        field: "statusOrder",
                        direction: "ASC",
                    },
                    {
                        field: "progress",
                        direction: "DESC",
                    },
                    {
                        field: "finalDate",
                        direction: "ASC",
                    }
                    ],
                    sqlWhereClause: {
                    condition: "OR",
                    rules: [],
                    },
                };
                
                this.controlAreasSelected?.forEach((ca) => {
                    payloadCA.sqlWhereClause.rules.push({
                        field: "issueOrigin",
                        type: "integer",
                        input: "number",
                        operator: "in",
                        value: [ca.id],
                    })
                })
            

                this.query = payloadCA;

                this.currentIndexTab = 0;
                this.loadSearchResult();

                break;   

                case 6:
                if (this.generalSearch.length == 0) {
                        this.loadingIconLocal = false;
                        this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast5"),
                        detail: this.$langGetValue("toast5Sub"),
                        life: 3000,
                        });
                        return;
                    }

                    var payloadReportNumber = {
                        orderBy: [
                        {
                            field: "statusOrder",
                            direction: "ASC",
                        },
                        {
                            field: "progress",
                            direction: "DESC",
                        },
                        {
                            field: "finalDate",
                            direction: "ASC",
                        }
                        ],
                        sqlWhereClause: {
                        condition: "AND",
                        rules: [],
                        },
                    };


                    payloadReportNumber.sqlWhereClause.rules.push({
                    condition: "OR",
                    rules: [],
                    })
                    this.generalSearch.forEach((word) => {
                        payloadReportNumber.sqlWhereClause.rules[0].rules.push({
                        field: "reportNumber",
                        type: "string",
                        input: "text",
                        operator: "contains",
                        value: word,
                        });
                    });

                    this.query = payloadReportNumber;

                    this.currentIndexTab = 0;
                    this.loadSearchResult();

                    break;
                case 5:
                if (!this.selectedStatus) {
                    this.loadingIconLocal = false;
                    this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("toast5"),
                    detail: this.$langGetValue("toast5Sub"),
                    life: 3000,
                    });
                    return;
                }

                var payloadStatus = {
                    orderBy: [
                    {
                        field: "statusOrder",
                        direction: "ASC",
                    },
                    {
                        field: "progress",
                        direction: "DESC",
                    },
                    {
                        field: "finalDate",
                        direction: "ASC",
                    }
                    ],
                    sqlWhereClause: {
                    condition: "AND",
                    rules: [],
                    },
                };
                
                payloadStatus.sqlWhereClause.rules.push({
                    condition: "OR",
                    rules: [],
                })
                this.selectedStatus.forEach((status) => {
                    payloadStatus.sqlWhereClause.rules[0].rules.push({
                        "field": "status",
                        "type": "integer",
                        "input": "text",
                        "operator": "equal",
                        "value": status.id
                    });
                });

                if (this.controlAreasSelected.length > 0) {
                        payloadStatus.sqlWhereClause.rules.push({
                            condition: "OR",
                            rules: [],
                        })
                        this.controlAreasSelected?.forEach((ca) => {
                            payloadStatus.sqlWhereClause.rules[1].rules.push({
                                field: "issueOrigin",
                                type: "integer",
                                input: "number",
                                operator: "in",
                                value: [ca.id],
                            })
                        })
                    }

                this.query = payloadStatus;

                this.currentIndexTab = 0;
                this.loadSearchResult();

                break;
            }
        },
        searchissueByID(issueID, issueMode) {

            if (this.selectedField.id != 1){
                this.currentIndexTab = null;
            }


            IssueService.getIssue(issueID).then((resp) => {
                if (resp.success == true) {
                    this.issueData = resp.data
                    this.issueMode = issueMode
                    this.loadissue(issueID)
                    this.loadingIconLocal = false;
                    
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue('toast8'),
                        detail: this.$langGetValue('toast8SubIssue'),
                        life: 10000,
                    });
                    this.loadingIconLocal = false;
                    return
                }
                
            })
        },
        async loadissue(issueID) {
            this.$router.push({ query: { id: issueID } })

            // Remove MyComponent from the DOM
            this.searchIssueReturn = false;

            // Wait for the change to get flushed to the DOM
            await this.$nextTick();

            // Add the component back in
            this.searchIssueReturn = true;
        },
        async loadSearchResult() {
            // Remove MyComponent from the DOM
            this.issueListReturn = false;

            // Wait for the change to get flushed to the DOM
            await this.$nextTick();

            // Add the component back in
            this.issueListReturn = true;
        }
    }
};
</script>

<style>
.customAccordionTab1 .p-accordion-tab .p-toggleable-content .p-accordion-content {
    padding: 0px;
}

.customLabel,
.customInput {
  width: 100% !important;
}

.query-builder,
.query-builder * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.query-builder {
    font-family: sans-serif;
}

.query-builder .hide {
    display: none;
}

.query-builder .pull-right {
    float: right !important;
}

.query-builder .btn {
    text-transform: none;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.query-builder .btn.focus,
.query-builder .btn:focus,
.query-builder .btn:hover {
    color: #333;
    text-decoration: none;
}

.query-builder .btn.active,
.query-builder .btn:active {
    background-image: none;
    outline: 0px none;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.125) inset;
}

.query-builder .btn-success {
    color: #FFF;
    background-color: #5CB85C;
    border-color: #4CAE4C;
}

.query-builder .btn-primary {
    color: #FFF;
    background-color: #337AB7;
    border-color: #2E6DA4;
}

.query-builder .btn-danger {
    color: #FFF;
    background-color: #D9534F;
    border-color: #D43F3A;
}

.query-builder .btn-success.active,
.query-builder .btn-success.focus,
.query-builder .btn-success:active,
.query-builder .btn-success:focus,
.query-builder .btn-success:hover {
    color: #FFF;
    background-color: #449D44;
    border-color: #398439;
}

.query-builder .btn-primary.active,
.query-builder .btn-primary.focus,
.query-builder .btn-primary:active,
.query-builder .btn-primary:focus,
.query-builder .btn-primary:hover {
    color: #FFF;
    background-color: #286090;
    border-color: #204D74;
}

.query-builder .btn-danger.active,
.query-builder .btn-danger.focus,
.query-builder .btn-danger:active,
.query-builder .btn-danger:focus,
.query-builder .btn-danger:hover {
    color: #FFF;
    background-color: #C9302C;
    border-color: #AC2925;
}

.query-builder .btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.query-builder .btn-group>.btn {
    position: relative;
    float: left;
}

.query-builder .btn-group>.btn:first-child {
    margin-left: 0px;
}

.query-builder .btn-group>.btn:first-child:not(:last-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.query-builder .btn-group>.btn:last-child:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.query-builder .btn-group .btn+.btn,
.query-builder .btn-group .btn+.btn-group,
.query-builder .btn-group .btn-group+.btn,
.query-builder .btn-group .btn-group+.btn-group {
    margin-left: -1px;
}

.query-builder .btn-xs,
.query-builder .btn-group-xs>.btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
</style>